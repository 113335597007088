<template>
    <section class="pt-4">
        <div v-for="(e,key) in envios" :key="key">
            <div class="row mx-0 pl-5">
                <p class="col-auto px-1 text-general f-17 f-500">
                    Intento de entrega No. {{ key+1 }}
                </p>
            </div>
            <div class="row mx-0 pl-5 mt-3">
                <img :src="e.foto_driver" class="obj-cover border br-6" width="63" height="63" />
                <div class="col-7 px-2">
                    <p class="text-general f-17">
                        {{ e.driver || 'No Registra' }}
                    </p>
                    <div class="row mx-0 mt-2">
                        <i class="icon-truck text-general f-18" />
                        <p class="col px-2 text-general f-300 lh-14">
                            <span class="f-15 text-general">{{ e.placa }}</span>
                            <br />
                            <span class="text-general f-13">{{ e.vehiculo }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 py-3" />
            <div v-for="(evi, key2) in e.evidencias" :key="key2" class="row mx-0 px-3 mb-3">
                <div class="col-auto px-1">
                    <i :class="`${iconosEvidencias(evi.tipo, evi.evidencia_tipo)} f-20`" />
                    <div class="border h-90 br-8 mx-auto" style="width:2px;" />
                </div>
                <div v-if="evi.tipo === 'estado'" class="col px-2 pt-1">
                    <p class="f-15 text-general">
                        {{ evi.estado_nombre }}
                    </p>
                    <p>
                        <i class="text-general2 f-13 text-date">
                            {{ evi.created_at | helper-fecha("DD-MM-YYYY hh:mm a") }}
                        </i>
                    </p>
                </div>
                <div v-else class="col px-2 pt-1">
                    <img v-if="evi.evidencia_tipo === 2" :src="evi.evidencia" class="obj-cover br-4" width="90" height="90" />
                    <p v-else-if="evi.evidencia_tipo === 1" class="f-15 text-general">
                        {{ evi.evidencia }}
                    </p>
                    <p v-else-if="evi.evidencia_tipo === 3" class="f-15 text-general">
                        <a :href="evi.evidencia" download>Archivo Adjunto</a>
                        <!-- {{ evi.evidencia }} LINK -->
                    </p>
                    <p>
                        <i class="text-general2 f-13 text-date">
                            {{ evi.created_at | helper-fecha("DD-MM-YYYY hh:mm a") }}
                        </i>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props:{
        envios:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return {
            url_image: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
            evidencias2: [
                {
                    icono: 1,
                    /* img: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg', */
                    estado: 'Estado 1',
                    fecha: '10-10-2021 - 10:01 a.m.'
                },
                {
                    icono: 2,
                    texto: 'No encuentro la ubicación de destino, así que voy a continuar con el siguiente tiro No. 57512',
                    fecha: '10-10-2021 - 10:01 a.m.'
                },
                {
                    icono: 3,
                    img: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
                    estado: 'Estado 1',
                    fecha: '10-10-2021 - 10:01 a.m.'
                }
            ]
        }
    },
    methods: {
        iconosEvidencias(tipo, tipo_evidencia){
            if(tipo == "estado") return 'icon-ok-circled-outline text-green'

            switch (tipo_evidencia){
            case 1:
                return 'icon-card-text-outline text-general'
            case 2:
                return 'icon-image-outline text-general'
            case 3:
                return 'icon-link text-general'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.text-date{

}
</style>
